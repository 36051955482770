<script setup lang="ts">
import { geoEntity } from '@/entities/geo'

import FooterIconAmex from './FooterIconAmex.vue'
import FooterIconDiscover from './FooterIconDiscover.vue'
import FooterIconJcb from './FooterIconJcb.vue'
import FooterIconMastercard from './FooterIconMastercard.vue'
import FooterIconVisa from './FooterIconVisa.vue'
import FooterLinks from './FooterLinks.vue'

type Props = {
  showTitle: boolean
}

withDefaults(defineProps<Props>(), {
  showTitle: true,
})

const { $analytics } = useNuxtApp()

const state = reactive({
  countryReady: false,
  isUSA: false,
})

onMounted(async () => {
  state.isUSA = await geoEntity.model.isUSA()
  state.countryReady = true
})

function onClickSubscription() {
  $analytics.event('main_screen_pressed', {
    button: 'subscription',
  })
}
function onClickPrivacy() {
  $analytics.event('main_screen_pressed', {
    button: 'privacy',
  })
}
function onClickCookies() {
  $analytics.event('main_screen_pressed', {
    button: 'policy',
  })
}
function onClickTerms() {
  $analytics.event('main_screen_pressed', {
    button: 'terms',
  })
}
</script>

<template>
  <footer class="w-full text-center">
    <div class="mb-32 px-16 lg:px-0">
      <div v-if="showTitle" class="text-h1-bold text-black-900 mb-24">
        {{ $t('shared.footer.online_random_video') }}
      </div>
      <FooterLinks
        class="mb-24"
        @subscription="onClickSubscription()"
        @privacy="onClickPrivacy()"
        @cookies="onClickCookies()"
        @terms="onClickTerms()"
      />

      <div v-if="state.countryReady">
        <p v-if="state.isUSA" class="text-h7-regular text-black-700">
          Social Discovery Limited, 2850 W. Horizon Ridge Parkway, Suite 200, Henderson, Nevada
          89052
        </p>
        <p v-else class="text-h7-regular text-black-700">
          WORIGIL LIMITED, Florinis 7, Greg Tower, 2nd floor, 1065, Nicosia, Cyprus
        </p>
      </div>
    </div>
    <div class="border-t-1 border-black-300 flex items-center justify-center border-solid py-12">
      <div class="text-h7-semibold text-black-900 mr-12">{{ $t('shared.footer.we_assept') }}:</div>
      <div class="flex gap-6">
        <FooterIconVisa />
        <FooterIconMastercard />
        <FooterIconDiscover />
        <FooterIconJcb />
        <FooterIconAmex />
      </div>
    </div>
  </footer>
</template>
