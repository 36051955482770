<script setup lang="ts"></script>

<template>
  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="35" height="24" rx="4" fill="white" />
    <path
      d="M22.3181 12.2873C22.8318 12.2984 23.3484 12.2646 23.86 12.3049C24.3781 12.4015 24.503 13.1859 24.0427 13.4428C23.7286 13.612 23.3555 13.5058 23.015 13.5357H22.3181V12.2873ZM24.157 10.8743C24.2711 11.2771 23.8828 11.6388 23.4948 11.5833H22.3181C22.3263 11.2034 22.302 10.7911 22.33 10.4312C22.8014 10.4445 23.2772 10.4041 23.7459 10.4523C23.9472 10.5029 24.1158 10.6684 24.157 10.8743ZM26.989 4.90042C27.0108 5.66972 26.9921 6.47968 26.9984 7.26459C26.997 10.4557 27.0015 13.6468 26.9959 16.8381C26.9752 18.0341 25.9153 19.0733 24.7277 19.0971C23.5388 19.102 22.3498 19.0979 21.1608 19.0992V14.2749C22.4563 14.2682 23.7524 14.2884 25.0474 14.2647C25.6481 14.227 26.3061 13.8307 26.334 13.1696C26.4048 12.5057 25.7788 12.0464 25.1845 11.9739C24.9561 11.968 24.9627 11.9073 25.1845 11.8808C25.7513 11.7583 26.1964 11.1717 26.0297 10.5841C25.8876 9.96618 25.2045 9.72701 24.6364 9.72819C23.4781 9.72032 22.3195 9.72709 21.1611 9.72481C21.1686 8.82416 21.1455 7.92255 21.1735 7.02256C21.2653 5.84819 22.3519 4.87971 23.5229 4.90073C24.6784 4.90055 25.8337 4.90055 26.989 4.90051V4.90042Z"
      fill="url(#paint0_linear_14355_221974)"
    />
    <path
      d="M8.07085 7.14441C8.10043 5.95034 9.16487 4.91967 10.351 4.90222C11.5355 4.89857 12.7201 4.90169 13.9045 4.90064C13.9013 8.89572 13.9109 12.8912 13.8996 16.8861C13.854 18.0657 12.8012 19.0767 11.6279 19.0976C10.4413 19.1019 9.25445 19.0982 8.06773 19.0994V14.1124C9.22043 14.3847 10.4292 14.5006 11.6052 14.32C12.3081 14.2069 13.0772 13.8617 13.3152 13.1324C13.4903 12.5086 13.3917 11.8521 13.4178 11.2119V9.72503H11.3827C11.3735 10.7084 11.4014 11.6935 11.3679 12.6757C11.3131 13.2794 10.7152 13.663 10.1458 13.6425C9.43969 13.65 8.04039 13.1307 8.04039 13.1307C8.03687 11.2883 8.06087 8.98091 8.07085 7.14458V7.14441Z"
      fill="url(#paint1_linear_14355_221974)"
    />
    <path
      d="M14.6631 10.3245C14.5559 10.3472 14.6415 9.9596 14.6141 9.81256C14.6214 8.88286 14.5989 7.95214 14.6266 7.0231C14.7181 5.84376 15.813 4.87287 16.9888 4.90048H20.4512C20.4479 8.89556 20.4576 12.8911 20.4463 16.886C20.4006 18.0655 19.3477 19.0765 18.1745 19.0974C16.9878 19.1019 15.8009 19.0981 14.6141 19.0993V13.6353C15.4246 14.3004 16.5262 14.4039 17.536 14.4057C18.2973 14.4054 19.0541 14.2881 19.7934 14.1125V13.1115C18.9602 13.5267 17.9809 13.7905 17.0573 13.5519C16.4131 13.3915 15.9454 12.7689 15.9559 12.1041C15.8812 11.4127 16.2865 10.6827 16.9659 10.4772C17.8095 10.2131 18.7289 10.415 19.5197 10.7584C19.6891 10.8471 19.8611 10.9571 19.7933 10.674V9.88716C18.4708 9.57251 17.0634 9.45672 15.7348 9.79902C15.3502 9.90751 14.9755 10.072 14.6631 10.3246V10.3245Z"
      fill="url(#paint2_linear_14355_221974)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_14355_221974"
        x1="21.121"
        y1="11.6962"
        x2="26.9616"
        y2="11.6962"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#58B03A" />
        <stop offset="1" stop-color="#55B330" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_14355_221974"
        x1="7.94354"
        y1="12.136"
        x2="13.8088"
        y2="12.136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0F6EB6" />
        <stop offset="1" stop-color="#006DBA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_14355_221974"
        x1="14.6055"
        y1="11.8338"
        x2="20.4501"
        y2="11.8338"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DE0D3D" />
        <stop offset="1" stop-color="#E30138" />
      </linearGradient>
    </defs>
  </svg>
</template>
