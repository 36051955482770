<script setup lang="ts"></script>

<template>
  <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="35" height="24" rx="4" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.75 17.1569C16.5584 18.1852 15.0128 18.806 13.3238 18.806C9.55511 18.806 6.5 15.7154 6.5 11.903C6.5 8.09057 9.55511 5 13.3238 5C15.0128 5 16.5584 5.62075 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
      fill="#ED0006"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.75 17.1569C19.2172 15.8908 20.1475 14.0068 20.1475 11.903C20.1475 9.79917 19.2172 7.91517 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
      fill="#F9A000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.75 6.64893C19.2172 7.91506 20.1476 9.79905 20.1476 11.9029C20.1476 14.0067 19.2172 15.8906 17.75 17.1568C16.2829 15.8906 15.3525 14.0067 15.3525 11.9029C15.3525 9.79905 16.2829 7.91506 17.75 6.64893Z"
      fill="#FF5E00"
    />
  </svg>
</template>
